@import '../../themes/default';

div.Header {
  background-image: url('../../assets/backgrounds/death-valley-dunes.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: $layout-header-height;

  div.NavigationBar {
    height: $space-x-large;
    padding: $space-large;

    div.Logo {
      font-size: $size-medium;
      font-weight: 600;
      text-transform: uppercase;

      a {
        text-decoration: none;
      }
    }
  }
}
