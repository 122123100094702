@import '../../themes/default';

html, body, div#root {
  height: 100%;
}

div.App {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: $layout-header-height auto;
  column-gap: 0;
  row-gap: 0;
  height: 100%;

  // Default all margin and padding to 0
  * {
    margin: 0;
    padding: 0;
  }

  // Default all links to black with no text-decoration
  a {
    color: $color-black;
    text-decoration: none;
    // Default all links to show an underline on hover
    &:hover {
      text-decoration: underline;
    }
  }

  div.Header {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  div.Content {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 4;
  }

  div.Footer {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}
