@import '../../themes/default';

div.Activities {
  @include border-radius($space-medium);
  background-color: $color-white-with-opacity-half;
  margin: $space-large;
  min-width: $layout-content-width;
  padding: $space-large;

  div.ActivitiesHeader {
    margin-bottom: $space-large;
  }

  div.ActivitiesSelect {
    div.ActivitiesSelectCheckbox {
      align-items: center;
      display: flex;

      label {
        padding-left: $size-small;
      }
    }
  }

  div.ActivitiesList {
    div.ActivityDay {
      &>:first-child {
        margin-top: $space-large;
      }
      
      div.ActivityDayDate {
        border-bottom: 1px solid $color-black;
        font-size: $size-medium;
      }
    }

    div.GithubActivity {
      display: flex;
      margin: $space-medium 0;

      div.Timestamp {
        flex: 1 1 20%;
      }

      div.Actor {
        align-items: center;
        display: flex;
        flex: 1 2 20%;

        img {
          max-height: $space-x-large;
          max-width: $space-x-large;
        }

        div.ActorLogin {
          margin: 0 $space-medium;
        }
      }

      div.EventType {
        flex: 1 1 20%;
      }

      div.Repository {
        flex: 2 2 40%;
      }
    }
  }
}
