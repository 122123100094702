@use "sass:math";
@import '../../themes/default';

$height-headshot: 325px;
$height-hello-name-tag: 100px;
$width-headshot: 350px;
$width-hello-name-tag: 150px;

div.About {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  max-width: $layout-content-width;
  padding: $space-x-large;

  img {
    height: 375px;
  }

  div.Headshot {
    background-image: url('../../assets/images/sand-boarding.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    flex-grow: 1;
    max-height: $height-headshot;
    min-height: $height-headshot;
    max-width: $width-headshot;
    min-width: $width-headshot;
    margin: $space-small $space-medium;

    div.HelloNameTag {
      @include border-radius($space-medium);
      @include box-shadow;
      @include rotate(-15deg);
      background-color: $color-red-dark;
      color: $color-white;
      display: flex;
      flex-direction: column;
      font-variant: all-petite-caps;
      max-height: $height-hello-name-tag;
      min-height: $height-hello-name-tag;
      max-width: $width-hello-name-tag;
      text-align: center;

      div.HelloNameTagHeader {
        font-size: $size-normal;

        div.HelloNameTagSubHeader {
          font-size: $size-small;
        }
      }

      div.HelloNameTagName {
        background-color: $color-white;
        color: $color-black;
        font-family: 'PermanentMarker';
        font-size: $size-large;
      }
    }
  }

  div.AboutContent {
    flex-grow: 1;
    font-size: $size-normal;
    grid-column: 2 / span 1;
    margin: $space-medium;
    max-width: math.div($layout-content-width, 2);

    a {
      font-weight: bold;

      &.LinkAmazon {
        color: $color-orange-amazon;
      }

      &.LinkGremlin {
        color: $color-green-gremlin;
      }

      &.LinkSalesforce {
        color: $color-blue-salesforce;
      }
    }
  }
}
